.app {
  color: #20006d;
  overflow: auto;
}

h1,
h2,
h3,
h4 {
  font-family: 'Montserrat', sans-serif;
}

.app-header {
  background-color: #20006d;
  padding: 0.75rem 1.5rem;
  display: flex;
  align-items: center;
}

h1 {
  font-weight: 800;
  font-size: 36px;
}

h2 {
  font-weight: 800;
  font-size: 26px;
}

.content {
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner {
  margin-top: 3rem;
  text-align: center;
  width: 100%;
}

.banner-img {
  max-width: 20rem;
  width: 100%;
  height: auto;
  margin: 2.5rem 0;
}

.logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.logo {
  max-width: 20.625rem;
  width: 100%;
  padding: 0.5rem 0;
  background-color: #000000;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.logo img {
  max-width: 9rem;
  height: auto;
  width: 100%;
}

.apple-logo img {
  max-width: 8rem;
  height: auto;
  width: 100%;
}

@media (min-width: 576px) {
  .logo {
    margin-right: 1rem;
  }
}
