html {
  font-family: 'Hind', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: #dbdbff;
}

body {
  margin: 0;
  padding: 0;
}
